<template>

  <div>
    <!-- Table Container Card -->
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <b-card no-body>
      <b-card-body>
        <b-row>
            <b-col cols="12" md="4" class="mb-md-0 mobile_tab_max_width_flex">
                <b-form-select v-model="siteData" @change="filterTable();">
                  <b-form-select-option value="" disabled>Select</b-form-select-option>
                  <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                </b-form-select>
            </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block "
                placeholder="Search..."
                @input="filterTable"
              />
              <!-- <b-button
                variant="warning"
                :to="{ name: 'add-email-user' }"
              >
                <span class="text-nowrap">Add User</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative midTable common_big_tbl"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <!-- Column: User -->

        <template #cell(site_name)="items">
           
            <span class="wordBreak">
              <div class="my-1">{{ items.item.site_name }}</div>
            </span>
         
        </template>

        <!-- <template #cell(location_name)="items">
           
            <span class="wordBreak">{{ items.item.location_name }}</span>
         
        </template> -->

        <template #cell(full_name)="items">
           
            <span class="wordBreak">{{ items.item.full_name | capitalize}}</span>
         
        </template>


        <template #cell(email)="items">
           
            <span class="wordBreak">{{ items.item.email}}</span>
         
        </template>

        <template #cell(status)="items">
           
            <b-badge
              pill
              :variant="variantColor(items.item.status)"
              class="text-capitalize"
            >
              {{ items.item.status}}
            </b-badge>
         
        </template>

        <!-- <template #cell(created_at)="items">
           
            <span class="wordBreak">{{ items.item.created_at | dateTime }}</span>
         
        </template> -->

        <template #cell(updated_at)="items">
           
            <span class="wordBreak" v-if="items.item.status == 'sent'">{{ items.item.updated_at | dateTime }}</span>
            <span v-if="items.item.status == 'pending'">N/A</span>
        </template>

        <template #cell(frequency)="items">
           
            <span class="wordBreak">{{ items.item.frequency }}</span>
         
        </template>

        <template #cell(type)="items">
           
            <span class="wordBreak">{{ items.item.type }}</span>
         
        </template>

        <!-- Column: Role -->

        <template #cell(actions)="items">

          <b-link
            v-if="checkPermission($route.name, 'Download')"
            v-b-tooltip.hover.v-warning
            title="Download"
            variant="outline-warning"
            @click="downloadFile(items.item.pdf)"
          >
              <feather-icon icon="DownloadIcon" class="mr-1 mediumSize"/>
              
          </b-link>
        </template>
        

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb, BCardBody,
  BFormSelect, BFormSelectOption, VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"

export default {
  components: {
    BCardBody,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BBreadcrumb,
    vSelect,
    BFormSelect,
    BFormSelectOption
  },
  directives: { 'b-tooltip': VBTooltip },
  data() {
    return {
       tableColumns: [
            { key: 'site_name', label: 'Project Site', sortable: true , thStyle:  {width: '15%'}},
            /*{ key: 'location_name', label: 'Location', sortable: true , thStyle:  {width: '12%'}},*/
            { key: 'full_name', label: 'Name', sortable: true , thStyle:  {width: '13%'}},
            { key: 'email', label: 'Email', sortable: true , thStyle:  {width: '19%'}},
            { key: 'status', label: 'Status', sortable: true , thStyle:  {width: '6%'}},
            { key: 'updated_at', label: 'Email Sended At', sortable: true , thStyle:  {width: '12%'}},
            { key: 'frequency', label: 'Frequency', sortable: true , thStyle:  {width: '13%'}},
            { key: 'type', label: 'Type', sortable: true , thStyle:  {width: '11%'}},
            { key: 'actions', label: '', sortable: false , thStyle:  {width: '7%'}},

          ],

        sites:[],
        siteData:'',
        
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null
    }
  },
  methods : {
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                     site: this.siteData != '' ? this.siteData :null,
                   },
                   api: '/api/email-logs'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'sent') {
        return 'success';
      }else{
        return 'primary';
      }
    },

    downloadFile(file){
      window.open(file,'_blank');
    },

    allSites(){
        return this.$store.dispatch(POST_API, {
            data:{
              role:this.$store.getters.currentUser.role,
              om_sites:this.$store.getters.currentUser.om_sites,
            },
            api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.sites = this.$store.getters.getResults.data;
                // if (this.sites.length == 1 ) {
                //     this.siteData = this.sites[0]._id;
                //     this.filterTable();
                //     this.updateDateFormat();
                // }
                var obj = {
                    _id:'all-site',
                    site_name:'All Project Sites',
                    pdf_date_format: 'DD MMM YYYY',
                    pdf_time_format: 'HH:mm'
                }
                this.sites.unshift(obj);
                
                this.siteData = this.defaultSite(this.sites)._id;
					      this.filterTable();
                
                return this.sites;
            }
        });
    },

    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Email Listing',
      },{
        to:null,
        text:'Email Logs',
        active:true
      }];
      return item;
    }
    
  },

  mounted(){
      this.allSites();
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
